<template>
    <Dialog v-model:visible="visible" modal :header="'Edit Account'" :style="{ width: '50vw' }">
        <div class="grid">
            <div class="col-12">
                <InputText class="w-full" v-model="newPassword" type="password" :placeholder="'New password'" />
            </div>
            <div class="col-12">
                <InputText class="w-full" v-model="confirmPassword" type="password" :placeholder="'Confirm password'" />
            </div>
            <div v-if="newSignInToken!==''" class="col-12">
                <span>
                    <b>Make a note of this rapid sign-in url as it will only show once</b>
                    <br/>
                    https://dashboard.cybertheftwatch.com/login?token={{ newSignInToken }}
                </span>
            </div>
            <div class="col-12">
                <Button class="w-full" @click="changePassword" label="Change Password" />
            </div>
        </div>
    </Dialog>
</template>

<script>
export default {
    name: "EditUser",

    data() {
        return {
            visible: false,

            newPassword: '',
            confirmPassword: '',

            newSignInToken: '',
        }
    },

    methods: {
        show() {
            this.newPassword = '';
            this.confirmPassword = '';
            this.newSignInToken = '';
            this.visible = true;
        },

        changePassword() {
            if (this.newPassword !== this.confirmPassword) {
                this.$emitter.emit("bad-toast", 'Passwords do not match');
            } else if (this.newPassword.length < 8) {
                this.$emitter.emit("bad-toast", 'Password must be at least 8 characters');
            } else {
                this.$factory.auth.changePassword(this.newPassword).then((data) => {
                    this.$emitter.emit("good-toast", 'Password changed');
                    this.newSignInToken = data.token;
                    this.newPassword = '';
                    this.confirmPassword = '';
                }).catch(() => {
                    this.$emitter.emit("bad-toast", 'Failed to change password');
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
