<template>
<OverlayPanel ref="healthcheckWindow" @hide="handleClose" style="max-width: 300px">
    <p class="text-center w-full mt-0">Refreshing in {{ refreshTimerCountdown }} seconds</p>
    <div v-if="loading">
        <Skeleton width="20rem" height="6rem" borderRadius="16px"></Skeleton>
    </div>
    <perfect-scrollbar v-else class="grid">
        <div v-for="(value, key) in serviceInformation" :key="key" class="col-12">
            <div class="border-round px-3 py-1" :style="{background: value.status==='DOWN' ? '#EF4444' : '#10B981' }">
                <p class="flex font-bold capitalize text-left text-lg">{{ key }}</p>
                <p>{{ value.timestamp }}</p>
            </div>
        </div>
    </perfect-scrollbar>
</OverlayPanel>
</template>

<script>
export default {
    name: "Healthcheck",

    data() {
        return {
            loading: false,
            allGood: undefined,

            serviceInformation: {},

            refreshTimer: null,
            refreshCountdownTimer: null,
            refreshTimerLastCheck: null,
            refreshTimerCountdown: 0,
        }
    },

    mounted() {
        console.debug("Healthcheck modal mounted")
        this.serviceInformation = {};

        this.getData();

        this.healthTimer = setInterval(() => {
            this.getData();
        }, 60000);

        this.refreshCountdownTimer = setInterval(() => {
            this.refreshTimerCountdown = this.updateCountdown();
        }, 1000);
    },

    unmounted() {
        console.debug("Healthcheck modal unmounted")
        clearInterval(this.healthTimer);
        clearInterval(this.refreshCountdownTimer);
    },

    methods: {
        show(event) {
            this.$refs.healthcheckWindow.toggle(event);
        },

        getData() {
            console.debug("Getting healthcheck data");
            this.loading = true;
            this.refreshTimerLastCheck = Date.now();
            this.$factory.adminPage.getHealthCheckData()
                .then(response => {
                    console.debug(response)

                    // Decode the value for example "DOWN 1723646045.801487" Should be red and the timestamp converted to a human readable format
                    // Also, show the difference between the current time and the timestamp in minutes
                    this.serviceInformation = Object.entries(response).reduce((acc, [key, value]) => {
                        const [status, timestamp] = value.split(" ");
                        const timestampDate = new Date(parseFloat(timestamp) * 1000);
                        const diff = Math.floor((Date.now() - timestampDate.getTime()) / 60000);

                        acc[key] = {
                            status,
                            timestamp: `${timestampDate.toLocaleString()} (${diff} minutes ago)`,
                            timestampDate: timestampDate.toLocaleString(),
                            diff: diff,
                        };

                        return acc;
                    }, {});

                    // Override the data for healthcheck, if the timestamp is over 5 minutes, then the status is down
                    this.serviceInformation['healthcheck'].status = this.serviceInformation['healthcheck'].diff > 5 ? 'DOWN' : 'UP';

                    this.checkAllGood();

                    console.debug(this.serviceInformation);
                })
                .catch(error => {
                    console.error(error);
                    this.$emitter.emit("bad-toast", "Failed to get healthcheck data.");
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        checkAllGood() {
            this.allGood = Object.values(this.serviceInformation).every(service => service.status === 'UP');
            this.$emitter.emit("health-check", this.allGood);
        },

        updateCountdown() {
            // Time in seconds from the last check
            const diff = Math.floor((Date.now() - this.refreshTimerLastCheck) / 1000);
            return 60 - diff;
        },

        handleClose() {
            console.debug("Closing healthcheck modal");
            // clearInterval(this.healthTimer);
            // clearInterval(this.refreshCountdownTimer);
        }
    }
}
</script>

<style scoped>

</style>
