<template>
    <Dialog v-model:visible="showCookieNotice" appendTo="body" :modal="true" :closable="false" :showHeader="false">
        <!--    <Dialog v-model:visible="showCookieNotice" appendTo="body" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '40vw'}" :closable="false" :showHeader="false">-->
        <div class="flex flex-column align-items-center my-4">
        <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
            <i class="pi pi-shield text-5xl"></i>
        </span>
            <div class="font-medium text-2xl text-900">Cookie Notice</div>
        </div>
        <p class="line-height-3 p-0 m-0">
            We use cookies to ensure that we give you the best security and experience on our website.
            If you continue to use this site we will assume that you are happy with it.
        </p>

        <div class="card flex flex-wrap justify-content-center">
            <div class="flex align-items-center ml-2 mt-2">
                <Checkbox v-model="cookieChoices" inputId="essential" name="essential" value="essential" :disabled="true"/>
                <label for="essential" class="ml-2"> Essential </label>
            </div>
            <div class="flex align-items-center ml-2 mt-2">
                <Checkbox v-model="cookieChoices" inputId="marketing" name="marketing" value="marketing" :disabled="true"/>
                <label for="marketing" class="ml-2"> Marketing </label>
            </div>
            <div class="flex align-items-center ml-2 mt-2">
                <Checkbox v-model="cookieChoices" inputId="statistics" name="statistics" value="statistics" :disabled="true"/>
                <label for="statistics" class="ml-2"> Statistics </label>
            </div>
            <div class="flex align-items-center ml-2 mt-2">
                <Checkbox v-model="cookieChoices" inputId="preferences" name="preferences" value="preferences" />
                <label for="preferences" class="ml-2"> Preferences </label>
            </div>
        </div>

        <template #footer>
            <div class=" border-top-1 surface-border pt-3 flex">
                <Button id="acceptCookies" icon="pi pi-check" @click="cookieAccept()" label="Yes" class="w-4 mr-2"></Button>

                <router-link to="/cookies" target="_blank" class="w-4 ml-2 mr-2" style="text-decoration: none; color: inherit;">
                    <Button icon="pi pi-book" label="Cookie Policy" class="w-full"></Button>
                </router-link>

                <Button icon="pi pi-times" @click="this.$store.commit('logoutNow')" label="No" class="p-button-outlined w-4 ml-2"></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
export default {
    name: "CookieNoticeModal",

    data() {
        return {
            showCookieNotice: !this.$cookies.get("cookieConsent").general,
            cookieChoices: ['essential', 'preferences'],
        }
    },

    methods: {
        cookieAccept() {
            let cookieOptions = this.$cookies.get("cookieConsent");
            cookieOptions.general = this.cookieChoices.includes('preferences');
            cookieOptions.preferences = this.cookieChoices.includes('preferences');
            this.$cookies.set("cookieConsent", cookieOptions, "30d");
            this.showCookieNotice = false;
        },
    }
}
</script>

<style scoped>

</style>
