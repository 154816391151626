<template>
    <ConfirmPopup></ConfirmPopup>
    <div class="min-h-screen w-screen flex relative lg:fixed surface-ground">
        <!-- Sidebar -->
        <div id="primary-sidebar" class="surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-0 surface-border select-none" style="width:200px">
            <div class="flex flex-column h-full">
                <div class="flex align-items-center px-5 flex-shrink-0" style="height:60px">
                    <p class="w-full text-left text-xl font-bold mb-4">CyberTheftWatch</p>
                </div>
                <perfect-scrollbar class="overflow-y-auto">
                    <!-- Top Segment -->
                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/priorities" v-ripple style="text-decoration: none; color: inherit;"
                                         class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-globe mr-2"></i>
                                <span class="font-medium text-lg">Priorities</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/assetviewer" v-ripple style="text-decoration: none; color: inherit;"
                             class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-home mr-2"></i>
                                <span class="font-medium text-lg">Asset Viewer</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/cases" v-ripple style="text-decoration: none; color: inherit;"
                                         class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-box mr-2"></i>
                                <span class="font-medium text-lg">Case Viewer</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul v-if="this.userRoles !== undefined && this.userRoles.includes('client')" class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/analytics" v-ripple style="text-decoration: none; color: inherit;"
                                         class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-chart-line mr-2"></i>
                                <span class="font-medium text-lg">Analytics</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/searches" v-ripple style="text-decoration: none; color: inherit;"
                                         class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-book mr-2"></i>
                                <span class="font-medium text-lg">Search Manager</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul v-if="this.userRoles !== undefined && this.userRoles.includes('client')" class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/submit" v-ripple style="text-decoration: none; color: inherit;"
                                         class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-shield mr-2"></i>
                                <span class="font-medium text-lg">Submit Piracy</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/clients" v-ripple style="text-decoration: none; color: inherit;"
                                         class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase mr-2"></i>
                                <span class="font-medium text-lg">Clients</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" class="list-none px-3 py-2 m-0">
                        <li>
                            <router-link to="/prospects" v-ripple style="text-decoration: none; color: inherit;"
                                         class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-eye mr-2"></i>
                                <span class="font-medium text-lg">Prospects</span>
                            </router-link>
                        </li>
                    </ul>

                    <!-- Data Review -->
                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('reviewer')" class="list-none px-3 py-3 m-0">
                        <div v-ripple class="p-2 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple"
                             @click="$preferences.data.sidebar.review = !$preferences.data.sidebar.review">
                            <span class="font-medium">DATA REVIEW</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <transition>
                            <ul class="list-none p-0 m-0 overflow-hidden" v-show="$preferences.data.sidebar.review">
                                <li v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')">
                                    <router-link to="/linkremoval" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Link Removal</span>
                                    </router-link>
                                </li>
                                <li v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('reviewer')">
                                    <router-link to="/domains" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Domain Review</span>
                                    </router-link>
                                </li>
                                <li v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('reviewer')">
                                    <router-link to="/preinject/ingest" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Preinject Ingest</span>
                                    </router-link>
                                </li>
                                <li v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('reviewer')">
                                    <router-link to="/hashes" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Torrent Hashes</span>
                                    </router-link>
                                </li>
                            </ul>
                        </transition>
                    </ul>

                    <!-- Active Assets -->
                    <ul class="list-none px-3 py-3 m-0">
                        <div v-ripple class="p-2 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple"
                             @click="$preferences.data.sidebar.active = !$preferences.data.sidebar.active">
                            <span class="font-medium">ACTIVE ASSETS</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <transition>
                            <ul class="list-none p-0 m-0 overflow-hidden" v-show="$preferences.data.sidebar.active">
                                <li v-for="asset in activeAssetData" v-bind:key="asset.id">
                                    <router-link :to="'/asset/'+asset.id" v-ripple style="text-decoration: none; color: inherit;"
                                     class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
    <!--                                        <i class="pi pi-ticket mr-2"></i>-->
                                        <span class="font-medium text-lg">{{ asset.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </transition>
                    </ul>

                    <!-- Inactive Assets -->
                    <ul class="list-none px-3 py-3 m-0">
                        <div v-ripple class="p-2 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple"
                             @click="$preferences.data.sidebar.inactive = !$preferences.data.sidebar.inactive">
                            <span class="font-medium">INACTIVE ASSETS</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <transition>
                            <ul class="list-none p-0 m-0 overflow-hidden" v-show="$preferences.data.sidebar.inactive">
                                <li v-for="asset in inactiveAssetData" v-bind:key="asset.id">
                                    <router-link :to="'/asset/'+asset.id" v-ripple style="text-decoration: none; color: inherit;"
                                     class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">{{ asset.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </transition>
                    </ul>

                    <!-- Jobs -->
                    <ul v-if="companyName === 'Cyber Theft Watch'" class="list-none px-3 py-3 m-0">
                        <div v-ripple class="p-2 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple"
                             @click="$preferences.data.sidebar.jobs = !$preferences.data.sidebar.jobs">
                            <span class="font-medium">JOBS</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <transition>
                            <ul class="list-none p-0 m-0 overflow-hidden" v-show="$preferences.data.sidebar.jobs">
                                <li v-if="this.userRoles.includes('admin')">
                                    <router-link to="/scheduled" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Scheduled Jobs</span>
                                    </router-link>
                                </li>
                                <li v-if="this.userRoles.includes('admin')">
                                    <router-link to="/cyberlockers" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Cyberlockers</span>
                                    </router-link>
                                </li>
                                <li v-if="this.userRoles.includes('admin')">
                                    <router-link to="/jobs" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Standard Jobs</span>
                                    </router-link>
                                </li>
                                <li v-if="this.userRoles.includes('submitter')">
                                    <router-link to="/manual" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Manual</span>
                                    </router-link>
                                </li>
                            </ul>
                        </transition>
                    </ul>

                    <!-- Admin -->
                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" class="list-none px-3 py-3 m-0">
                        <div v-ripple class="p-2 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple"
                             @click="$preferences.data.sidebar.admin = !$preferences.data.sidebar.admin">
                            <span class="font-medium">ADMIN</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <transition>
                            <ul class="list-none p-0 m-0 overflow-hidden" v-show="$preferences.data.sidebar.admin">
                                <li >
                                    <router-link to="/admin/analytics" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Analytics</span>
                                    </router-link>
                                </li>
                                <li >
                                    <router-link to="/admin/admin" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Admin Page</span>
                                    </router-link>
                                </li>

                                <li >
                                    <router-link to="/providers" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Content Providers</span>
                                    </router-link>
                                </li>
                            </ul>
                        </transition>
                    </ul>

                    <!-- Settings -->
                    <ul v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" class="list-none px-3 py-3 m-0">
                        <div v-ripple class="p-2 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple"
                             @click="$preferences.data.sidebar.settings = !$preferences.data.sidebar.settings">
                            <span class="font-medium">SETTINGS</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <transition>
                            <ul class="list-none p-0 m-0 overflow-hidden" v-show="$preferences.data.sidebar.settings">
                                <li>
                                    <router-link to="/filters" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">Filter Settings</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/settings" v-ripple style="text-decoration: none; color: inherit;"
                                                 class="flex align-items-center cursor-pointer px-3 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <span class="font-medium text-lg">System Settings</span>
                                    </router-link>
                                </li>

                            </ul>
                        </transition>
                    </ul>
                </perfect-scrollbar>
            </div>
        </div>

        <!-- Main Content -->
        <perfect-scrollbar class="max-h-screen flex flex-column flex-auto overflow-y-scroll">
            <!-- Top Bar -->
            <div class="surface-0 sticky top-0 z-5">
                <!-- Client visible topbar -->
                <div class="flex justify-content-between align-items-center px-5" style="height:40px">
                    <div class="flex">
                        <a v-ripple class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple"
                           v-styleclass="{ selector: '#primary-sidebar', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }">
                            <i class="pi pi-bars text-4xl"></i>
                        </a>
                    </div>

                    <p class="absolute left-50 text-center text-xl font-bold mb-4 fadein" style="transform: translateX(-50%);">{{ $route.name }}</p>

                    <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple"
                       v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                        <i class="pi pi-ellipsis-v text-base lg:text-4xl"></i>
                    </a>
                    <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section right-0 top-100 z-100 absolute lg:static" style="height:40px">
                        <li>
                            <a v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')"
                               v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:text-900 hover:surface-100 font-medium cursor-pointer
                               transition-duration-150 transition-colors p-ripple" @click="showHealthCheck"
                               :class="allGoodStatus ? 'text-green-500' : 'text-orange-500'">
                                <i class="pi pi-compass text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                <span class="block lg:hidden font-medium">Health Check</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer
                        transition-duration-150 transition-colors p-ripple" @click="changeTheme($event)">
                                <i class="pi pi-sun text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                <span class="block lg:hidden font-medium">Toggle Theme</span>
                            </a>
                        </li>
                        <li>
                            <div class="flex p-3 lg:px-3 lg:py-2 align-items-center font-medium hover:surface-100 hover:border-200 p-ripple cursor-pointer" @click="editUser">
                                <Avatar image="images/profile-pic.png" class="mr-3" shape="circle" style="width: 25px; height: 25px;"/>
                                <div class="block">
                                    <div class="text-900 font-medium text-sm">{{ username }}</div>
                                    <span class="text-600 font-medium text-sm">{{ companyName }}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer
                        transition-duration-150 transition-colors p-ripple" @click="signOut($event)">
                                <i class="pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                <span class="block lg:hidden font-medium">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Main Body -->
            <router-view :key="$route.path" id="main-body"/>

            <!-- Footer -->
            <Footer/>
        </perfect-scrollbar>
    </div>

    <CookieNoticeModal/>
    <ToastEmitter/>
    <EditUser ref="editUser"/>
    <Healthcheck v-if="companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')" ref="healthCheck"/>
</template>

<script>
import Footer from "@/views/shared/Footer"
import ToastEmitter from "@/components/emitter/ToastEmitter";
import CookieNoticeModal from "@/components/modals/CookieNoticeModal";
import EditUser from "@/components/modals/EditUser";
import Healthcheck from "@/components/modals/Healthcheck";

export default {
    components: {
        CookieNoticeModal,
        ToastEmitter,
        Footer,
        EditUser,
        Healthcheck
    },

    data() {
        return {
            scale: 16,

            showDataReview: true,

            activeAssetData: [],
            inactiveAssetData: [],

            userInfo: null,
            username: null,
            companyId: null,
            companyName: null,
            userRoles: null,

            location: window.location.href,

            healthTimer: null,
            allGoodStatus: false,
        }
    },

    created() {
        this.applyPreferences();
    },

    beforeMount() {
        this.getAssets();
    },

    mounted() {
        this.checkSiteHealth();

        this.healthTimer = setInterval(() => {
            this.checkSiteHealth();
        }, 30000);

        this.$emitter.on('asset-created', () => {
            console.debug("Asset created event received");
            this.getAssetsImmediate();
        });

        this.$emitter.on("logout", () => {
            this.$store.commit("logoutNow");
            this.signOut();
        });

        this.$emitter.on("health-check", (response) => {
            console.debug("Health check event received");
            this.allGoodStatus = response;
        });
    },

    unmounted() {
        clearInterval(this.healthTimer);
    },

    methods: {
        checkSiteHealth() {
            this.$factory.auth.getSiteHealth()
                .then(response => {
                    if (response.data.content === "pong") {
                        return;
                    } else if (response.status === 401 || response.status === 403 || response.status === 429) {
                        clearInterval(this.healthTimer);
                        this.$emitter.emit('logoutNow');
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },

        applyPreferences() {
            console.debug("Applying preferences", this.$preferences.data);

            // theme
            let themeElement = document.getElementById('theme-link');
            themeElement.setAttribute('href', themeElement.getAttribute('href').replace("lara-light-blue", this.$preferences.data.theme));
            this.$store.commit("setTheme", this.$preferences.data.theme === "lara-light-blue" ? "light" : "dark");
        },

        changeTheme(event) {
            let themeElement = document.getElementById('theme-link');
            if (this.$preferences.data.theme === "lara-light-blue") {
                themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$preferences.data.theme, "vela-blue"));
                this.$preferences.data.theme = "vela-blue";
                this.$store.commit("setTheme", "dark");
            } else {
                themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$preferences.data.theme, "lara-light-blue"));
                this.$preferences.data.theme = "lara-light-blue";
                this.$store.commit("setTheme", "light");
            }
            event.preventDefault();
        },

        createSidebarAssets() {
            this.activeAssetData = this.$store.getters.getActiveAssetData.filter(asset => !asset.type.includes("Prospect"));
            this.activeAssetData.sort((a,b)=> (a.name > b.name ? 1 : -1))
            this.inactiveAssetData = this.$store.getters.getInactiveAssetData.filter(asset => !asset.type.includes("Prospect"));
            this.inactiveAssetData.sort((a,b)=> (a.name > b.name ? 1 : -1))
        },

        getAssets() {
            if (this.$store.getters.hasAssets) {
                this.createSidebarAssets();
            } else {
                this.getAssetsImmediate();
            }
        },

        getAssetsImmediate() {
            this.$factory.asset.getAllAssets()
                .then(data => {
                    this.$store.commit("setAssets", data);
                    this.createSidebarAssets();
                    this.$emitter.emit('assets-fetched');
                })
                .catch(error => {
                    console.error("Error fetching assets", error);
                    this.$emitter.emit("bad-toast", "Error - Data can not be fetched, please try again later");
                });
        },

        signOut() {
            this.$factory.auth.logout()
                .then(() => {
                    console.debug("Successful logout");
                })
                .catch(error => {
                    console.debug("Error logging out " + error)
                })
                .finally(() => {
                    this.$store.dispatch("logout");
                    this.$router.push("/login");
                });
        },

        editUser() {
            this.$refs.editUser.show();
        },

        showHealthCheck(event) {
            console.debug(this.$refs.healthCheck.allGood);
            this.$refs.healthCheck.show(event);
        }
    },

    watch: {
        '$store.getters.needLogout': {
            handler() {
                if (this.$store.getters.needLogout) {
                    console.debug("logout event received")
                    this.$store.commit("logoutNow");
                    this.signOut();
                }
            },
            deep: true,
            immediate: true,
        },
        '$store.state.userinfo': {
            handler() {
                let userInfo = this.$store.state.userinfo;
                this.username = userInfo.username;
                this.companyId = userInfo.companyid;
                this.companyName = userInfo.companyname;
                this.userRoles = userInfo.roles;
            },
            deep: true,
            immediate: true,
        },
        '$route.name': {
            handler() {
                console.debug("Route changed to " + this.$route.name);
                console.debug("Path changed to " + this.$route.path);
            },
            deep: true,
            immediate: true,
        }
    },
}
</script>

<style lang="scss">
    @import '../assets/styles/layout.scss';
    @import '../style/main.css';

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.3s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>
